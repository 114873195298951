.no-data-container {
  font-family: 'HEX Franklin v0.2';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
}

.clickable-item {
  cursor: pointer;
}
.bordered-item-4 {
  border-radius: 4px;
  border: 1px solid $grey-8;
}
.MuiTooltip-tooltip {
  border-radius: 0 !important;
  box-shadow: 0px 0px 0 rgb(120 114 114 / 71%), 0 0px 0px #605d5d;

  p {
    color: $white;
    font-size: 10px;
    max-width: 160px;
    font-family: 'HEX Franklin v0.2';
    font-style: normal;
    font-weight: 400;
    margin: 0;
  }
}

.common-checkbox {
  svg {
    width: 16px;
    height: 16px;
  }

  &.Mui-disabled:not(.Mui-checked) > div {
    background: var(--Grey-5, #ebeaea) !important;
    border-radius: 2px;
    svg {
      visibility: hidden;
    }
  }
}

.impact-score-chip {
  border-radius: 6px !important;
  background-color: $purple-6 !important;
  color: $purple-3 !important;
  border: none !important;
  height: 26px !important;

  .impact-score-label {
    padding: 5px 8px !important;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
  }
}
.skeleton-container {
  min-height: '148px';
  display: 'flex';
  flex-direction: 'column';
  gap: '10px';
}
.popover {
  margin-top: 0.5rem;
  width: 235px;
  border-radius: 8px !important;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03) !important;
}
.common-tooltip-popper {
  .common-tooltip-container {
    max-width: 470px;
    border-radius: 4px !important;
    background-color: $white;
    padding: 6px 15px;
    filter: drop-shadow(5px 5px 7px rgba(112, 112, 112, 0.339));
  }
  .common-tooltip-arrow {
    filter: drop-shadow(5px 5px 7px rgba(112, 112, 112, 0.339));
    color: $white;
  }
}

.nested-list-container {
  max-height: 60vh;
  overflow: auto;
}

.nested-list-loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.nested-list-no-data-container {
  padding: 1rem;
  width: 100%;
  text-align: center;
  font-size: 14px;
}

.nested-list-text-container {
  gap: 4px !important;
  display: flex;
  flex-direction: column;

  .nested-list-text-primary {
    color: var(--Grey-1, #3d3d3d);
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
  }

  .nested-list-text-secondary {
    color: var(--Grey-3, #7f7f7f);
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
  }
}

.nested-list-text-placeholder {
  height: 20px;
}
.simple-tag-component {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  justify-content: center;
  border-radius: 6px;

  &.warning {
    color: $red-3;
    path {
      fill: $red-3;
    }

    &.bordered {
      border: 1px solid $red-6;
    }
  }

  &.info {
    color: $green-3;
    path {
      fill: $green-3;
    }

    &.bordered {
      border: 1px solid $green-6;
    }
  }

  &:not(.bordered) {
    &.warning {
      border: none;
    }

    &.info {
      border: none;
    }
  }
}

.Toastify {
  .Toastify__toast-icon {
    display: none !important;
  }

  .Toastify__toast {
    color: var(--orange-900, #771d1d) !important;
    font-family: 'HEX Franklin v0.2' !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 150% !important;
    border-radius: 6px !important;
    border: 1px solid var(--Orange-200, #fdd3b6) !important;
    background: var(--orange-50, #fff8f1) !important;
  }

  button {
    top: 50% !important;
    transform: translateY(-50%) !important;

    svg {
      path {
        fill: var(--orange-900, #771d1d) !important;
      }
    }
  }
}
