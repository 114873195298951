.top-insights-widget {
  height: fit-content;
  max-height: 425px;

  .no-data-container {
    height: auto !important;
  }
  .widgetContainer {
    min-height: 165px;
  }
  .widgetArea {
    height: fit-content;
  }

  .empty-widget-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .insights-widget-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 100%;
    column-gap: 40px;

    p {
      margin: 0;
    }

    .insight-block {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 12px;
      border-bottom: 1px solid var(--Grey-5, #ebeaea);
      padding: 13px 0;
      cursor: pointer;
      &:last-child,
      &:nth-last-child(2) {
        border: none;
        padding-bottom: 0px;
      }

      .icon {
        width: 32px;
        height: 32px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .medium {
        background: var(--Orange-6, #ffe9da);
        path {
          fill: var(--Orange-1, #9f5a1a);
        }
      }

      .high {
        background: var(--Red-6, #ffe1e6);
        path {
          fill: var(--Red-3, #e02b4b);
        }
      }

      .critical {
        background: #f4dde1;
        path {
          fill: var(--Red-1, #761727);
        }
      }

      .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        margin-bottom: 5px;

        .finding-count {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          height: 20px;
          border: 1px solid var(--Grey-5, #ebeaea);
          border-radius: 4px;
          color: var(--Purple-3, #7547dc);
          font-family: 'HEX Franklin v0.2';
          font-size: 12px;
          font-style: normal;
          font-weight: 588;
          line-height: 20px;
          padding: 1px 6px;

          path {
            fill: var(--Grey-1, #3d3d3d);
          }
        }
      }
      .description {
        p {
          color: var(--Grey-3, #7f7f7f);
        }
      }
    }
  }
}
