.campaign-modal-container {
  .common-modal-header-title {
    color: #3d3d3d !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 588 !important;
    line-height: 28px !important;
  }
}

.campaign-create-form-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.campaign-create-form-card {
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(94, 105, 132, 0.45);
  border-radius: 16px;
  padding: 2rem;
  min-width: 35vw;
}

.campaign-create-form {
  input::placeholder {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #c7c4c4 !important;
    opacity: 1 !important;
  }

  .campaign-create-form-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .campaign-create-form-header-close-button {
      cursor: pointer;
      user-select: none;
    }
  }

  .campaign-create-form-group {
    width: 100%;
  }

  .campaign-create-form-body {
    max-height: 50vh;
    overflow: scroll;

    &::-webkit-scrollbar {
      width: 10px;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .campaign-create-form-date-section {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .campaign-create-form-date-section {
    .campaign-create-form-date-section-inputs {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  .campaign-create-form-footer {
    margin-top: 1rem;
    display: flex;
    gap: 8px;
    justify-content: end;
  }

  .campaign-create-form-input-label {
    margin-bottom: 0.5rem;
  }

  .campaign-create-form-error-text {
    color: #e84545;
    font-size: 12px;
    padding: 0;
    margin-top: 5px;
  }

  .campaign-create-form-input-error-outline {
    border: 1px solid #e84545 !important;
  }
}

// Updated campaign create form style

.campaign-create-modal-form-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .campaign-create-modal-form-helper-text {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    color: var(--Grey-1, #3d3d3d);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    background-color: var(--Grey-6, #f5f5f5);
    border-radius: 10px;

    svg {
      path {
        fill: var(--Grey-3, #7f7f7f) !important;
      }
    }
  }

  .campaign-create-modal-form-loading {
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .campaign-create-modal-form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-height: 60vh;
    overflow: auto;

    .campaign-create-modal-form-label-text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #3d3d3d;
    }

    .campaign-create-form-error-text {
      color: $red-3;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
    }

    .campaign-create-modal-form-name-label {
      margin-bottom: 0.5rem;
    }

    .campaign-create-modal-input {
      input {
        padding: 11px 14px !important;
        background: #ffffff !important;
        border: 1px solid $grey-4 !important;
        border-radius: 4px !important;

        &::placeholder {
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: 14px !important;
          color: $grey-3 !important;
        }
      }

      fieldset {
        display: none !important;
      }
    }

    .campaign-create-modal-select {
      .MuiInputBase-root {
        background: #ffffff !important;
        border: 1px solid $grey-4 !important;
        border-radius: 4px !important;
      }

      fieldset {
        display: none !important;
      }

      .MuiChip-root {
        height: 24px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: $grey-1;

        img {
          height: 16px;
          width: 16px;
          border-radius: 50%;
        }
      }

      input {
        padding: 6px !important;
      }
    }

    .campaign-create-form-date-picker {
      .MuiTypography-body1 {
        font-family: 'HEX Franklin v0.2';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: $grey-1;
      }

      .MuiLink-root {
        color: $grey-3;
      }

      input {
        padding: 11px 14px !important;
        background: #ffffff !important;
        border: 1px solid $grey-4 !important;
        border-radius: 4px !important;
        text-align: left !important;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: $grey-1;

        &::placeholder {
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: 14px !important;
          color: #c7c4c4 !important;
        }
      }

      .date-picker-input-outline {
        display: none !important;
      }

      .date-picker-input-icon {
        padding-right: 0.5rem;
      }
    }
  }

  .campaign-create-modal-form-live {
    .campaign-create-modal-form-live-label {
      margin-left: 0px !important;
      display: flex;
      align-items: center;
      gap: 9px;
    }

    .common-checkbox {
      padding: 0 !important;
    }

    .campaign-create-modal-form-live-info {
      display: flex;
      align-items: center;
      gap: 10px;
      color: var(--Grey-1, #3d3d3d);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      svg {
        path {
          fill: var(--Grey-3, #7f7f7f) !important;
        }
      }
    }
  }

  .campaign-create-modal-form-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .campaign-create-modal-form-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
    }
  }
}

.campaign-create-modal-select-popper {
  p {
    font-family: 'HEX Franklin v0.2';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: $grey-1;
  }

  li {
    div {
      align-items: center;
    }
  }
}

.campaign-create-modal-live-warning {
  color: var(--Grey-1, #3d3d3d);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border: 1px solid var(--Grey-5, #ebeaea);
  padding: 8px 15px;
  border-radius: 8px;
}
